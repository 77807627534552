<template>
  <div>
    <v-list-item @click.stop="dialog = true">
      <v-list-item-title>
        {{ name }}
      </v-list-item-title>
    </v-list-item>
    <v-dialog v-model="dialog" :width="size" persistent>
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on">
          {{ name }}
        </v-btn>
      </template> -->

      <v-card>
        <v-card-title>
          {{ title }}
        </v-card-title>

        <v-card-text>
          {{ text }}
          <v-form ref="text">
            <v-text-field
              v-model="inputText"
              :label="label"
              class="mt-4"
              :rules="textRule"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancelDiscard">
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="onAccept">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    // dialog: {
    //   type: Boolean,
    //   default: false
    // },
    name: {
      type: String,
      default: "Descartar."
    },
    title: {
      type: String,
      default: "Confirmación."
    },
    text: {
      type: String,
      default: "¿Seguro de que quieres realizar esta acción?"
    },
    label: {
      type: String,
      default: "¿Seguro de que quieres realizar esta acción?"
    },
    size: {
      type: String,
      default: "600"
    },
    textRule: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialog: false,
      inputText: ""
      //textRule: [v => !!v || "Campo obligatorio"]
    };
  },
  methods: {
    onAccept() {
      if (this.$refs.text.validate()) {
        this.$emit("accepted", this.inputText);
        this.dialog = false;
        this.inputText = "";
      }
    },
    cancelDiscard() {
      this.dialog = false;
      this.inputText = "";
      this.$refs.text.resetValidation();
    }
  }
};
</script>
